import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import ReactMarkdownWrapper from '../../components/ReactMarkdownWrapper';
import Container from '../../components/Container';

import about1 from '../../images/about-1.jpg';
import about2 from '../../images/about-2.jpg';
import * as styles from './styles.module.scss';

const About: FC = () => {
  const {
    allStrapiLandingPageTextContent: {
      nodes: [data],
    },
    allStrapiLandingPageTextContentWhoTextTextnode: {
      nodes: [whoData],
    },
    allStrapiLandingPageTextContentAboutTextTextnode: {
      nodes: [aboutData],
    },
  } = useStaticQuery(graphql`
    query AboutQuery {
      allStrapiLandingPageTextContent {
        nodes {
          about_title
          who_title
        }
      }
      allStrapiLandingPageTextContentWhoTextTextnode {
        nodes {
          who_text
        }
      }
      allStrapiLandingPageTextContentAboutTextTextnode {
        nodes {
          about_text
        }
      }
    }
  `);

  return (
    <Container title="" anchor="about">
      <div className={styles.aboutContainer}>
        <div className={styles.box}>
          <h3>{data.about_title}</h3>
          <ReactMarkdownWrapper>{aboutData.about_text}</ReactMarkdownWrapper>
        </div>
        <div className={styles.box}>
          <h3>{data.who_title}</h3>
          <ReactMarkdownWrapper>{whoData.who_text}</ReactMarkdownWrapper>
        </div>
        <div className={styles.cardContainer}>
          <div className={styles.imgWrapper}>
            <LazyLoadImage alt="cossacks about 1" src={about1} />
          </div>
          <div className={styles.imgWrapper}>
            <LazyLoadImage alt="cossacks about 2" src={about2} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default About;
