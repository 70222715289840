import React, { FC, memo } from 'react';
import Logo from '../../images/logo_ua.svg';
import * as styles from './styles.module.scss';

const PoweredBy: FC = () => {
  return (
    <a href="https://wesoftyou.com/" className={styles.wrapper} target="_blank" rel="noopener nofollow">
      <img src={Logo} alt="WeSoftYou" width="49" height="42" />
      <div className={styles.column}>
        <p className={styles.title}>Powered by</p>
        <p className={styles.name}>WeSoftYou </p>
      </div>
    </a>
  );
};

export default memo(PoweredBy);
