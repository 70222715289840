import cossack1 from '../../images/collection/img-1.jpg';
import cossack2 from '../../images/collection/img-2.jpg';
import cossack3 from '../../images/collection/img-3.jpg';
import cossack4 from '../../images/collection/img-4.jpg';
import cossack5 from '../../images/collection/img-5.jpg';
import cossack6 from '../../images/collection/img-6.jpg';
import cossack7 from '../../images/collection/img-7.jpg';
import cossack8 from '../../images/collection/img-8.jpg';

export interface IGallery {
  mobile: string;
  tablet: string;
  desktop: string;
}

export const GALLERY: IGallery[] = [
  {
    mobile: cossack1,
    tablet: cossack1,
    desktop: cossack1,
  },
  {
    mobile: cossack2,
    tablet: cossack2,
    desktop: cossack2,
  },
  {
    mobile: cossack3,
    tablet: cossack3,
    desktop: cossack3,
  },
  {
    mobile: cossack4,
    tablet: cossack4,
    desktop: cossack4,
  },
  {
    mobile: cossack5,
    tablet: cossack5,
    desktop: cossack5,
  },
  {
    mobile: cossack6,
    tablet: cossack6,
    desktop: cossack6,
  },
  {
    mobile: cossack7,
    tablet: cossack7,
    desktop: cossack7,
  },
  {
    mobile: cossack8,
    tablet: cossack8,
    desktop: cossack8,
  },
];
