import React, { FC, ReactNode } from 'react';

import * as styles from './styles.module.scss';

interface IContainer {
  children: ReactNode;
  title: string;
  subTitle?: string;
  anchor?: string;
  label?: string;
  component?: ReactNode;
}

const Container: FC<IContainer> = ({ title, children, anchor, label, component, subTitle }) => {
  return (
    <section className={styles.container} id={anchor}>
      <div className={styles.header}>
        <div className={styles.leftColumn}>
          {label && <span className={styles.label}>{label}</span>}
          <h2 className={styles.title}>{title}</h2>
          {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
        </div>
        {component && component}
      </div>
      {children}
    </section>
  );
};

export default Container;
