import React, { memo } from 'react';
import { useWindowDimensions } from '../../../utils/hooks/useWindowDimensions';
import SkeletonLoader from '../../SkeletonLoader';

const CollectionSkeleton = () => {
  const { width: viewportWidth } = useWindowDimensions();

  if (viewportWidth <= 500) {
    return (
      <SkeletonLoader width={351} height={327}>
        <rect width="327" height="327" fill="#F3F3F3" />
        <rect x="336" width="15" height="327" fill="#F3F3F3" />
      </SkeletonLoader>
    );
  }

  if (viewportWidth <= 768 && viewportWidth > 500) {
    return (
      <SkeletonLoader width={726} height={334}>
        <rect width="334" height="334" fill="#F3F3F3" />
        <rect x="350" width="334" height="334" fill="#F3F3F3" />
        <rect x="700" width="26" height="334" fill="#F3F3F3" />
      </SkeletonLoader>
    );
  }
  return (
    <SkeletonLoader width={1280} height={630}>
      <rect width="305" height="305" fill="#F3F3F3" />
      <rect x="325" width="305" height="305" fill="#F3F3F3" />
      <rect x="650" width="305" height="305" fill="#F3F3F3" />
      <rect x="975" width="305" height="305" fill="#F3F3F3" />
      <rect y="325" width="305" height="305" fill="#F3F3F3" />
      <rect x="325" y="325" width="305" height="305" fill="#F3F3F3" />
      <rect x="650" y="325" width="305" height="305" fill="#F3F3F3" />
      <rect x="975" y="325" width="305" height="305" fill="#F3F3F3" />
    </SkeletonLoader>
  );
};

export default memo(CollectionSkeleton);
