import React, { FC, memo, ReactNode } from 'react';
import ContentLoader from 'react-content-loader';

interface ISkeletonLoader {
  width: number;
  height: number;
  children: ReactNode;
  className?: string;
}

const SkeletonLoader: FC<ISkeletonLoader> = ({ width, height, children, className }) => {
  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      className={className}
    >
      {children}
    </ContentLoader>
  );
};

export default memo(SkeletonLoader);
