import React, { FC } from 'react';
import { ToastContainer } from 'react-toastify';

import Header from '../containers/Header';
import Main from '../containers/Main';
import Footer from '../containers/Footer';
import PageContainer from '../containers/PageContainer';
// import GiveAway from '../containers/GiveAway';
import ThemeProvider from '../containers/ThemeProvider';

import WalletProvider from '../containers/WalletProvider';

import WhitelistModalProvider from '../containers/WhitelistModalProvider';

import '../sass/basic.scss';
import GiftBanner from '../containers/GiftBanner';

const IndexPage: FC = () => {
  return (
    <ThemeProvider>
      <WalletProvider>
        <WhitelistModalProvider>
          <GiftBanner />
          <PageContainer>
            <Header />
            {/*<GiveAway />*/}
            <Main />
            <Footer />
          </PageContainer>
        </WhitelistModalProvider>
        <ToastContainer newestOnTop limit={1} />
      </WalletProvider>
    </ThemeProvider>
  );
};

export default IndexPage;
