import React, { useMemo, FC, useState, useCallback } from 'react';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import cn from 'classnames';

import Container from '../Container';
import { useCountdown } from './hooks';
import { GALLERY, IGallery } from './consts';
import { imgCollection } from '../../images/collection';
import { useWindowDimensions } from '../../utils/hooks/useWindowDimensions';

import * as styles from './styles.module.scss';
import VIEWS from '../../constants/VIEWS';
import CollectionSkeleton from './components/CollectionSkeleton';

const TARGET_DATE: Date = new Date('June 28, 2022 18:00:00 GMT+02:00');

const arr: boolean[] = [];

const Collection: FC = () => {
  const [isSkeleton, setIsSkeleton] = useState<boolean>(true);
  const [days, hours, minutes, seconds] = useCountdown(TARGET_DATE);
  const { width: viewportWidth } = useWindowDimensions();

  const isReleased = useMemo<boolean>(() => {
    const timer = days + hours + minutes + seconds;
    return timer <= 0;
  }, [days, hours, minutes, seconds]);

  const handleSkeletonChange = useCallback(() => {
    let MIN_LOADED_IMAGES: number = imgCollection.length;
    arr.push(true);

    if (viewportWidth <= 500) {
      MIN_LOADED_IMAGES = 1;
    }
    if (viewportWidth <= 768 && viewportWidth > 500) {
      MIN_LOADED_IMAGES = 2;
    }

    if (arr.length === MIN_LOADED_IMAGES) {
      setIsSkeleton(false);
    }
  }, [viewportWidth]);

  const imageContainer = cn(styles.cossacks, {
    [styles.cossacks_loading]: isSkeleton,
  });

  if (isReleased) {
    return (
      <Container title="collection preview">
        <div className={styles.grid}>
          {GALLERY.map((cossacks: IGallery, index: React.Key) => {
            return (
              <LazyLoadComponent key={index}>
                <picture>
                  <source media={VIEWS.MOBILE} srcSet={cossacks.mobile} />
                  <source media={VIEWS.TABLET} srcSet={cossacks.tablet} />
                  <img src={cossacks.desktop} alt="image" />
                </picture>
              </LazyLoadComponent>
            );
          })}
        </div>
      </Container>
    );
  }

  return (
    <section className={styles.container}>
      <span>Whitelist pre-sale in</span>
      <h3>{`${days} days  ${hours} h ${minutes} m ${seconds} s`}</h3>
      <p className={styles.subtitle}>collection preview</p>
      <div className={imageContainer}>
        {isSkeleton && <CollectionSkeleton />}
        {imgCollection.map((img: string, i: number) => (
          <LazyLoadImage key={i} alt="" src={img} afterLoad={handleSkeletonChange} />
        ))}
      </div>
    </section>
  );
};

export default Collection;
