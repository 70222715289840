interface IGetWindowDimensions {
  width: number;
  height: number;
}

export const getWindowDimensions = (): IGetWindowDimensions => {
  let newWindow;
  if (typeof window == 'undefined') {
    newWindow = { innerWidth: 1280, innerHeight: 1280 };
  } else {
    newWindow = window;
  }

  const { innerWidth: width, innerHeight: height } = newWindow;
  return {
    width,
    height,
  };
};
