import React, { FC } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Container from '../../components/Container';
import { useThemeStore } from '../ThemeProvider/context';
import CBA from '../../images/partners/comeBackAlive.svg';
import CBADark from '../../images/partners/comeBackAlive_dark.svg';
import PCF from '../../images/partners/CF_prytula.svg';
import PCFDark from '../../images/partners/CF_prytula_dark.svg';
import KOLOWhite from '../../images/partners/kolo-white.svg';
import KOLODark from '../../images/partners/kolo-black.svg';
import * as styles from './styles.module.scss';

const OurPartners: FC = () => {
  const partners = [
    {
      url: 'https://www.koloua.com/',
      logo_light: KOLODark,
      logo_dark: KOLOWhite,
    },
    // {
    //   url: 'https://www.comebackalive.in.ua/',
    //   logo_light: CBA,
    //   logo_dark: CBADark,
    // },
    // {
    //   url: 'https://prytulafoundation.org/en',
    //   logo_light: PCF,
    //   logo_dark: PCFDark,
    // },
  ];

  const { isDarkTheme } = useThemeStore();

  return (
    <Container title="Our partners" anchor="partners">
      <div className={styles.container}>
        {partners.map(({ url, logo_dark, logo_light }) => (
          <a className={styles.link} href={url} target="_blank" rel="noopener nofollow">
            <LazyLoadImage src={isDarkTheme ? logo_dark : logo_light} />
          </a>
        ))}
      </div>
    </Container>
  );
};

export default OurPartners;
