import React, { FC } from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import Team from '../Team';
import About from '../About';
import OurPartners from '../OurPartners';
import Media from '../Media';
import ComingSoon from '../ComingSoon';
import RoadMap from '../RoadMap';
import Events from '../Events';
import Collection from '../../components/Collection';
import DonateBoard from '../DonateBoard';
import Banner from '../Banner';
import FAQ from '../FAQ';
import SEO from '../../components/SEO';

const mintFlag = process.env.GATSBY_MINT_FLAG && process.env.GATSBY_MINT_FLAG.toLowerCase() === 'true';

const Main: FC = () => {
  const {
    allStrapiLandingPageTextContent: {
      nodes: [data],
    },
  } = useStaticQuery(
    graphql`
      query MintDescriptionBlockQuery {
        allStrapiLandingPageTextContent {
          nodes {
            main_short_text
            main_title
          }
        }
      }
    `,
  );

  return (
    <main>
      <SEO />
      <Banner active textData={data} whitelist={true} />
      <DonateBoard active={mintFlag} />
      <Collection />
      <OurPartners />
      {/* <Media /> */}
      <About />
      <Events />
      {/* <RoadMap /> */}
      <ComingSoon />
      <Team />
      <FAQ />
    </main>
  );
};

export default Main;
