import React, { FC, useEffect, useState, useCallback } from 'react';
import Modal from '../../../../components/Modal';
import { CloseIcon } from '../../../../images/icons';
import * as styles from './styles.module.scss';
import GiftInnerBox from '../../../GiftInnerBox';
import PlaneImage from '../../_assets/plane.png';
import Button from '../../../../components/Button';

const BANNER_FLAG = 'gift-modal-is-closed';

const GiftInfoModal: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useEffect(() => {
    const flag = localStorage.getItem(BANNER_FLAG);
    if (flag) return;
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    // localStorage.setItem(BANNER_FLAG, 'true');
  }, []);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
  }, [isOpen]);

  // just to be sure
  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <Modal modalClassName={styles.modal} open={isOpen} onClose={handleClose}>
      <div className={styles.inner}>
        <button className={styles.closeBtn} onClick={handleClose}>
          <img src={CloseIcon} alt="" />
        </button>
        <GiftInnerBox />
        <div className={styles.content}>
          <img className={styles.plane} src={PlaneImage} alt="plane" />
          <div className={styles.textWrapper}>
            <div className={styles.title}>
              CRYPTOCOSSACKS and KOLO raising funds to purchase a bunch of Mavics for the Ukrainian army.
            </div>
            <div className={styles.description}>
              Buy NFTs, get a branded CryptoCossacks T-shirt* and become a part of those who joined the donations!
              <br></br>
              <br></br>
              <small>*free shipment over Ukraine</small>
            </div>
            <Button size="medium" onClick={handleClose}>
              Buy NFT
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GiftInfoModal;
