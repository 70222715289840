import React, { FC } from 'react';
import { useThemeStore } from '../../containers/ThemeProvider/context';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {
  FbIcon,
  IgIcon,
  LinkedinIcon,
  TwitterIcon,
  FbDarkIcon,
  IgDarkIcon,
  LinkedinDarkIcon,
  TwitterDarkIcon,
} from '../../images/icons';
import Img from '../Img';
import * as styles from './styles.module.scss';

interface ICardProps {
  avatarSrc: string;
  name: string;
  position: string;
  links: {
    facebook?: string;
    instagram?: string;
    linkedin?: string;
    twitter?: string;
  };
}

const Card: FC<ICardProps> = ({ avatarSrc, name, position, links }) => {
  const { isDarkTheme } = useThemeStore();

  return (
    <article className={styles.card}>
      <div className={styles.imgWrapper}>
        <LazyLoadImage src={avatarSrc} alt={name} />
      </div>
      <div className={styles.description}>
        <h3 className={styles.title}>{name}</h3>
        <div className={styles.position}>{position}</div>
        <ul>
          {links.facebook && (
            <li>
              <a href={links.facebook} target="_blank" rel="noopener nofollow">
                <img src={isDarkTheme ? FbDarkIcon : FbIcon} alt="facebook" />
              </a>
            </li>
          )}

          {links.instagram && (
            <li>
              <a href={links.instagram} target="_blank" rel="noopener nofollow">
                <img src={isDarkTheme ? IgDarkIcon : IgIcon} alt="instagram" />
              </a>
            </li>
          )}
          {links.linkedin && (
            <li>
              <a href={links.linkedin} target="_blank" rel="noopener nofollow">
                <img src={isDarkTheme ? LinkedinDarkIcon : LinkedinIcon} alt="linkedin" />
              </a>
            </li>
          )}
          {links.twitter && (
            <li>
              <a href={links.twitter} target="_blank" rel="noopener nofollow">
                <img src={isDarkTheme ? TwitterDarkIcon : TwitterIcon} alt="twitter" />
              </a>
            </li>
          )}
        </ul>
      </div>
    </article>
  );
};

export default Card;
