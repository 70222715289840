import React, { FC, useState, useCallback, Key } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Container from '../../components/Container';
import Accordion from '../../components/Accordion';

interface INode {
  text: { data: { text: string } };
  title: string;
}

const FAQ: FC = () => {
  const {
    allStrapiFaq: { nodes },
  } = useStaticQuery(graphql`
    query FAQQuery {
      allStrapiFaq(sort: { fields: order_number, order: ASC }) {
        nodes {
          text {
            data {
              text
            }
          }
          title
        }
      }
    }
  `);
  const [openAccordion, setOpenAccordion] = useState<string>('');

  const toggleHandler = useCallback((title): void => {
    setOpenAccordion((prev) => (title === prev ? '' : title));
  }, []);

  return (
    <Container title="FAQ" anchor="faq">
      {nodes.map((item: INode, i: Key | null | undefined) => (
        <Accordion key={i} {...item} isOpen={item.title === openAccordion} toggleHandler={toggleHandler} />
      ))}
    </Container>
  );
};

export default FAQ;
