import React, { FC } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Button from '../../components/Button';
import Container from '../../components/Container';
import * as styles from './styles.module.scss';
import MeetTheCryptoCossacks from '../../images/meet_the_crypto_cossacks.jpeg';
import NFTNYC2022 from '../../images/nft_nyc_2022.jpeg';

interface IEvents {
  title: string;
  description: string;
  date: string;
  buttonUrl: string;
  img: string;
}

const events: IEvents[] = [
  {
    title: 'Meet the CryptoCossacks Club',
    description: `It's the first online presentation of the Crypto Cossacks Club Avatar collection. Meeting participants will be the first to see art objects before the sale. You will meet the project's creators and become a part of a powerful NFT community.`,
    date: 'April 2022',
    buttonUrl: '',
    img: MeetTheCryptoCossacks,
  },
  {
    title: 'CryptoCossacks Club NFT.NYC 2022',
    description: `Did someone say “New York”? We are already there to represent Crypto Cossacks Club at NYC 2022. Don't miss the opportunity to get to know us better.`,
    date: 'June 2022',
    buttonUrl: '',
    img: NFTNYC2022,
  },
];

const Events: FC = () => {
  return (
    <Container title="Past events" anchor="events">
      <div className={styles.wrapper}>
        {events.map((item, i) => (
          <article className={styles.eventItem} key={i}>
            <div className={styles.image}>
              <LazyLoadImage src={item.img} alt="event" width="260" height="280" />
            </div>
            <div className={styles.description}>
              <div className={styles.descriptionWrapper}>
                <time className={styles.eventTime}>{item.date}</time>
                <h3 className={styles.eventTitle}>{item.title}</h3>
                <p className={styles.eventDescriptions}>{item.description}</p>
              </div>
              {!!item.buttonUrl && (
                <Button target="_blank" rel="noopener nofollow" link href={item.buttonUrl}>
                  Join
                </Button>
              )}
            </div>
          </article>
        ))}
      </div>
    </Container>
  );
};

export default Events;
