import React, { FC } from 'react';
import Container from '../../components/Container';
import Img from '../../components/Img';

import * as styles from './styles.module.scss';
import girl_1 from '../../images/girl_collection.jpg';
import girl_2 from '../../images/girl_collection_2.jpg';

const amountImg: string[] = [girl_1, girl_2];

const ComingSoon: FC = () => {
  return (
    <Container title="Cossack-girls collection" label="Coming soon">
      <div className={styles.wrapper}>
        <div className={styles.cardContainer}>
          {amountImg.map((image, i) => (
            <Img key={i} src={image} alt="soon cossacks" />
          ))}
        </div>
        <div className={styles.box}>
          <h3 className={styles.boxTitle}>Who is Cossack-girl?</h3>
          <p className={styles.boxDescription}>
            She is so indescribably beautiful that you might think that she is weak. But this is a misleading
            impression. Cossack-girl is an indomitable guardian of the genus.
          </p>
          <p className={styles.boxDescription}>
            She builds a business and raises children. She fights for peace with weapons in her hands or engages in
            volunteer activities. Cossack girl is a real femme fatale.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default ComingSoon;
