import React, { FC, ReactNode, useState, memo, useEffect } from 'react';
import { navigate } from 'gatsby';
import { WhitelistModalContext } from './context';

interface IWhitelistModalProvider {
  children: ReactNode;
}

const WAIT_MS_BEFORE_SHOW = 2000;

const WhitelistModalProvider: FC<IWhitelistModalProvider> = ({ children }) => {
  const [isWhitelistModalShow, setIsWhitelistModalShow] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsWhitelistModalShow(true);
  //   }, WAIT_MS_BEFORE_SHOW);
  // }, []);

  const handleClose = () => {
    setIsWhitelistModalShow(false);
  };

  const handleMintNowClick = () => {
    navigate('/whitelist-minting');
  };

  return (
    <WhitelistModalContext.Provider
      value={{
        isWhitelistModalShow,
        handleClose,
        handleMintNowClick,
      }}
    >
      {children}
    </WhitelistModalContext.Provider>
  );
};

export default memo(WhitelistModalProvider);
