import React, { FC, useMemo } from 'react';

import * as styles from './styles.module.scss';
import KoloWhite from '../../images/partners/kolo-white-with-text-svg.svg';
import KoloDark from '../../images/partners/kolo-black-with-text-svg.svg';
import { useThemeStore } from '../ThemeProvider/context';

const DonateBoard: FC = () => {
  const { isDarkTheme } = useThemeStore();

  const kolo = useMemo(() => (isDarkTheme ? KoloWhite : KoloDark), [isDarkTheme]);

  return (
    <div className={styles.container}>
      <div className={styles.where}>Where does money go?</div>
      <div className={styles.koloWrapper}>
        <img src={kolo} alt="kolo" />
      </div>
      <div className={styles.info}>
        <div className={styles.title}>100%</div>
        <div className={styles.description}>
          All of profit goes to KOLO: <br />
          Ukrainian army and Ukrainians in need.
        </div>
      </div>
    </div>
  );
};

export default DonateBoard;
