import React, { FC, useMemo } from 'react';
import * as styles from './styles.module.scss';
import Tshirt from './_assets/t-shirt.png';
import KoloWhite from '../../images/partners/kolo-white-with-text-svg.svg';
import KoloDark from '../../images/partners/kolo-black-with-text-svg.svg';
import HardWhite from '../../images/partners/hard-white-with-text-svg.svg';
import HardDark from '../../images/partners/hard-black-with-text-svg.svg';
import { useThemeStore } from '../ThemeProvider/context';

const GiftInnerBox: FC = () => {
  const { isDarkTheme } = useThemeStore();

  const kolo = useMemo(() => (isDarkTheme ? KoloWhite : KoloDark), [isDarkTheme]);
  const hard = useMemo(() => (isDarkTheme ? HardWhite : HardDark), [isDarkTheme]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <img className={styles.kolo} src={kolo} alt="kolo" />
        <h2>For every NFT bought you get Cryptocossacks branded T-Shirt</h2>
      </div>
      <img className={styles.tShirtImage} src={Tshirt} alt="t-shirt" />
      <img className={styles.hard} src={hard} alt="t-shirt" />
    </div>
  );
};

export default GiftInnerBox;
