import React, { FC, useMemo } from 'react';
import * as styles from './styles.module.scss';
import Tshirt from './_assets/t-shirt.png';
import Cossacks from './_assets/cossacks.png';
import KoloWhite from '../../images/partners/kolo-white-with-text-svg.svg';
import KoloDark from '../../images/partners/kolo-black-with-text-svg.svg';
import HardWhite from '../../images/partners/hard-white-with-text-svg.svg';
import HardDark from '../../images/partners/hard-black-with-text-svg.svg';
import { useThemeStore } from '../ThemeProvider/context';
import GiftInfoModal from './_components/GiftInfoModal';

const GiftBanner: FC = () => {
  const { isDarkTheme } = useThemeStore();

  const kolo = useMemo(() => (isDarkTheme ? KoloWhite : KoloDark), [isDarkTheme]);
  const hard = useMemo(() => (isDarkTheme ? HardWhite : HardDark), [isDarkTheme]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.mainBlock}>
          <div className={styles.text}>For every NFT bought</div>
          <img className={styles.tShirtImage} src={Tshirt} alt="t-shirt" />
          <div className={styles.text}>you get Cryptocossacks branded T-Shirt</div>
        </div>
        <div className={styles.extraBlock}>
          <img className={styles.kolo} src={kolo} alt="kolo" />
          <img className={styles.cossacks} src={Cossacks} alt="cossacks" />
          <img src={hard} alt="hard" />
        </div>
      </div>
      <GiftInfoModal />
    </>
  );
};

export default GiftBanner;
