import { createContext, useContext } from 'react';

interface IWhitelistModalContext {
  isWhitelistModalShow: boolean;
  handleClose: () => void;
  handleMintNowClick: () => void;
}

const initialContextValue = {
  isWhitelistModalShow: false,
  handleClose: () => {},
  handleMintNowClick: () => {},
};

const WhitelistModalContext = createContext<IWhitelistModalContext>(initialContextValue);

const useWhitelistModal = () => useContext(WhitelistModalContext);

export { WhitelistModalContext, useWhitelistModal };
