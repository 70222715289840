import React, { FC } from 'react';

import Container from '../../components/Container';
import Card from '../../components/Card';
import PoweredBy from '../../components/PoweredBy';

import {
  Max_P,
  Andrew_K,
  Georgy_K,
  Alex_O,
  // Alisa_M,
  Annastasia_T,
  Max_O,
  Yana_M,
  Lena_T,
  Valeria_R,
} from '../../images/team';
import * as styles from './styles.module.scss';

const teammates = [
  {
    name: 'Maksym Petruk',
    avatarSrc: Max_P,
    position: 'Idea',
    links: {
      facebook: 'https://www.facebook.com/maksym.petruk',
      instagram: 'https://www.instagram.com/maksym.petruk/',
      linkedin: 'https://www.linkedin.com/in/maksym-petruk/',
      twitter: 'https://twitter.com/maksympetruk',
    },
  },
  {
    name: 'Andrew Kovalchuk',
    avatarSrc: Andrew_K,
    position: 'Technology',
    links: {
      facebook: 'https://www.facebook.com/andrii.kovalchuk.ua/',
      instagram: 'https://www.instagram.com/andreykko_/',
      linkedin: 'https://www.linkedin.com/in/andrii-kovalchuk-ua/',
      twitter: 'https://twitter.com/andreykko',
    },
  },
  {
    name: 'Lena Tsysar',
    avatarSrc: Lena_T,
    position: 'Business Development',
    links: {
      instagram: 'https://instagram.com/lena.tsysar',
    },
  },
  // {
  //   name: 'Alisa Mitlitska',
  //   avatarSrc: Alisa_M,
  //   position: 'Management',
  //   links: {
  //     facebook: 'https://www.facebook.com/alisa.mitlitskaya',
  //     linkedin: 'https://www.linkedin.com/in/alisa-mitlitska-0131b6176/',
  //   },
  // },
  {
    name: 'Anastasiia Taran',
    avatarSrc: Annastasia_T,
    position: 'Communication',
    links: {
      facebook: 'https://www.facebook.com/people/Anastasia-Taran/100072803086773/',
      instagram: 'https://www.instagram.com/talisvyanberg/?hl=en',
      linkedin: 'https://www.linkedin.com/in/anastasia-taran-322880188/',
    },
  },
  {
    name: 'Valeria Rozhenok',
    avatarSrc: Valeria_R,
    position: 'SMM',
    links: {
      instagram: 'https://instagram.com/r_lerok',
      linkedin: 'https://www.linkedin.com/in/valeria-rozhenok/',
    },
  },
  {
    name: 'Georgy Kozhushko',
    avatarSrc: Georgy_K,
    position: 'Technology',
    links: {
      facebook: 'https://www.facebook.com/georgsby',
      instagram: 'https://www.instagram.com/georgsby/',
      linkedin: 'https://www.linkedin.com/in/georgsby/',
    },
  },
  {
    name: 'Alex Ostrovny',
    avatarSrc: Alex_O,
    position: 'Technology',
    links: {
      twitter: 'https://twitter.com/Alex_Ostrovny',
    },
  },
  {
    name: 'Yana Mitlitska',
    avatarSrc: Yana_M,
    position: 'Art',
    links: {
      instagram: 'https://www.linkedin.com/in/yana-mitlitska-569a421b4/',
      linkedin: 'https://www.instagram.com/yana.mitl',
    },
  },

  {
    name: 'Max Ostrozinski',
    avatarSrc: Max_O,
    position: 'Design',
    links: {
      facebook: 'https://www.facebook.com/ostrozhinskiy.maxim',
      instagram: 'https://www.instagram.com/max_ostrz/',
      linkedin: 'https://www.linkedin.com/in/maxim-ostrozhinskiy/',
    },
  },
];

const Team: FC = () => {
  return (
    <Container title="People behind the project" anchor="team" component={<PoweredBy />}>
      <div className={styles.cardContainer}>
        {teammates.map((member, i) => (
          <Card key={i} {...member} />
        ))}
      </div>
    </Container>
  );
};

export default Team;
