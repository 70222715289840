import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';

function LinkRenderer(props: any) {
  return (
    <a href={props.href} target="_blank" rel="noreferrer nofollow">
      {props.children}
    </a>
  );
}

const ReactMarkdownWrapper: FC = ({ children }) => (
  <ReactMarkdown components={{ a: LinkRenderer }} linkTarget="_blank">
    {children}
  </ReactMarkdown>
);

export default ReactMarkdownWrapper;
