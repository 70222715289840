import React, { FC } from 'react';
import * as styles from './styles.module.scss';
import ReactMarkdownWrapper from '../ReactMarkdownWrapper';

interface IAccordion {
  title: string;
  text: { data: { text: string } };
  isOpen: boolean;
  toggleHandler: (title: string) => void;
}

const Accordion: FC<IAccordion> = ({ title, text, isOpen, toggleHandler }) => {
  const handler = () => toggleHandler(title);

  return (
    <div id={title} onClick={handler} className={styles.block} aria-expanded={isOpen}>
      <div className={styles.title}>
        {title}
        <span className={styles.arrow}></span>
      </div>
      <div className={styles.description}>
        <ReactMarkdownWrapper>{text.data.text}</ReactMarkdownWrapper>
      </div>
    </div>
  );
};

export default React.memo(Accordion);
